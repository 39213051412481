// React
import React from "react";
import {navigate} from "gatsby";

// Constants
import * as routes from "../../constants/routes";

// Components
import BodyContainer from "../BodyContainer";
import EmptyStateView from "../EmptyStateView";

// Material ui
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import BrokenImage from "@material-ui/icons/BrokenImage";
import ArrowBack from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // height: '100vh',
    marginTop: 100,
    display: "flex",
    textAlign: "center",
    height: 1000,
  },
  content: {
    marginTop: 'auto',
    marginBottom: 'auto',
    textAlign: "center",
    margin: "auto",
  },
}));

export default function PageNotFound(props) {
  const classes = useStyles();
  const {message, redirectRoute, icon} = props;
  return (
    <BodyContainer topGutter bottomGutter>
      <div className={classes.content}>
        <Typography variant="h5" component="h1" align="center">
          {message || "We could not find this page"}
        </Typography>
        {icon ? (
          icon
        ) : (
          <EmptyStateView
            size="xl"
            text="Let's find a better place for you to go instead."
            icon={<BrokenImage/>}
            buttonIconLeft={<ArrowBack/>}
            buttonText="Go back home"
            onButtonClick={() => navigate(redirectRoute || routes.home)}
          />
        )}

      </div>
    </BodyContainer>
  );
}
