import React from "react";
import PageNotFound from "../components/PageNotFound";
import {PublicNavbar} from "../components/Navbars";

export default function NotFound() {
  return (
    <div>
      <PublicNavbar/>
      <PageNotFound/>
    </div>
  );
}
